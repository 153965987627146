import React from 'react'
import { Link } from 'gatsby'
import RoundArrowButton from '../../atoms/RoundArrowButton'
import DottedNav from '../../molecules/DottedNav'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './iphonesAndContent.module.scss'


class iphonesAndContent extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      windowHeight: 750
    }
  }

  componentDidMount () {
    this.setState({ windowHeight: window.innerHeight })
  }

  render () {
    const { title, content, cta_text, internal_link, images = [], nav = {}, unmount = false, invert } = this.props
    const { animated, windowHeight } = this.state

    return (
      <ScrollMonitoringWrapper offsets={{ top: (-windowHeight / 2) }} isInViewportEvent={() => this.setState({ animated: true })}>
        <div className={`${styles.iphonesAndContent} ${animated && !unmount && styles.animated} ${invert && styles.invert}`}>
          <div>
            <div className={styles.phones}>
              { images.map(({ image: { alt_text, source_url } }) => <img src={source_url} alt={alt_text} />) }
            </div>
            <div className={styles.content}>
              <div>
                <h2><span>{ title }</span></h2>
                <div className={styles.copy} dangerouslySetInnerHTML={{ __html: content }} />
                <div className={styles.links}>
                  { cta_text && internal_link && <RoundArrowButton to={internal_link} text={cta_text}  /> }
                </div>
                { nav && nav.count > 0 && <DottedNav nav={nav} change={nav.change} /> }
              </div>
            </div>
          </div>
        </div>
      </ScrollMonitoringWrapper>
    )
  }
}

export default iphonesAndContent
