import React from 'react'
import { Link } from 'gatsby'
import Image from '../../atoms/Image'
import { getSlug } from '../../../lib/helpers'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './featuredShowcaseCarousel.module.scss'

class FeaturedShowcaseCarousel extends React.Component {
  constructor () {
    super()

    this.state = {
      mounted: false,
      animated: false,
      index: 0,
      prepareAnimation: false,
      isVisible: false
    }
  }

  animateSlide (i) {
    this.setState({ prepareAnimation: true }, () => setTimeout(() => this.setState({ animated: false }), 500))
    setTimeout(() => this.setState({ index: i ? i : (this.state.index + 1) === this.props.slides.length ? 0 : this.state.index + 1 }), 675)
    setTimeout(() => this.setState({ prepareAnimation: false }, () => setTimeout(() => this.setState({ animated: true }), 500)), 650)
  }

  prevSlide () {
    this.setState({ prepareAnimation: true }, () => setTimeout(() => this.setState({ animated: false }), 500))
    setTimeout(() => this.setState({ index: this.state.index === 0 ? (this.props.slides.length - 1) : this.state.index - 1 }), 675)
    setTimeout(() => this.setState({ prepareAnimation: false }, () => setTimeout(() => this.setState({ animated: true }), 500)), 650)
    clearInterval(this.interval)
  }

  nextSlide () {
    this.animateSlide()
    clearInterval(this.interval)
  }

  goToSlide (i) {
    this.animateSlide(i)
    clearInterval(this.interval)
  }

  initialSlideShow () {
    this.setState({ mounted: false })
    this.interval = setInterval(() => this.animateSlide(), 9000)
  }

  componentDidMount () {
    setTimeout(() => this.setState({ mounted: true }, () => {
      setTimeout(() => {
        this.setState({ animated: true })

        if (this.props.slides && this.props.slides.length > 1) {
          this.initialSlideShow()
        }
      }, 50)
    }), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render () {
    const { animated, index, prepareAnimation, mounted, isVisible } = this.state
    const { slides, navigation_title } = this.props

    return (
      <ScrollMonitoringWrapper offsets={{ top: -200 }} isInViewportEvent={isVisible => this.setState({ isVisible })}>
      <section id={navigation_title && `${navigation_title}-id`} data-is-visible={isVisible} className={`${styles.featuredShowcaseCarousel} ${mounted && `${styles.mounted}`} ${animated && `${styles.animated}`} ${prepareAnimation && `${styles.prepareAnimation}`}`}>
        <div className={`${styles.main} container`}>
          <div className={styles.imageWrapper}>
            <Image {...slides[index].image} />
          </div>
          <div className={styles.content}>
            <span dangerouslySetInnerHTML={{ __html: slides[index].title }} />
            <div className={styles.inner}>
              <div className={styles.caption} dangerouslySetInnerHTML={{ __html: slides[index].caption }} />
              {
                slides[index].specifications &&
                <>
                  <div className={styles.specs}>
                    <h5>Specifications</h5>
                    <ul>{ slides[index].specifications.map(({ title }) => <li>{ title }</li>) }</ul>
                  </div>
                  <div className={styles.rating}>
                    { Array.apply(null, Array(parseInt(slides[index].rating))).map(() => <i className="fa fa-star"></i>) }
                  </div>
                </>
              }
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className="container">
            <div className={styles.links}>
              { slides[index].external_link && <a href={slides[index].external_link} target="_blank" rel="noopener noreferrer" rel="noopener noreferrer">View Website</a> }
              { slides[index].case_brief_link && <Link to={getSlug(slides[index].case_brief_link)}>View Case Brief</Link> }
            </div>
            <div className={styles.carousel}>
              <button onClick={() => this.prevSlide()}><i className="fa fa-chevron-left"></i></button>
              {
                slides.slice(0, 3).map(({ image = {} }, i) => (
                  <div className={index === i && `${styles.active}`}>
                    <button onClick={() => this.goToSlide(i)}></button>
                    <div className={styles.imageWrapper}>
                      <Image {...image} />
                    </div>
                  </div>
                ))
              }
              <button onClick={() => this.nextSlide()}><i className="fa fa-chevron-right"></i></button>
            </div>
            <div className={styles.callout}>
              <p><strong>Already know what you want?</strong>Shoot me a message with your thoughts and I will get back to you right away</p>
              <Link to="/contact" className="btn">Get in touch</Link>
            </div>
          </div>
        </div>
      </section>
      </ScrollMonitoringWrapper>
    )
  }
}

export default FeaturedShowcaseCarousel
