import React from 'react'
import { Link } from 'gatsby'
import Image from '../../atoms/Image'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './icon-banner.module.scss'

class IconBanner extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      windowHeight : 750
    }
  }

  componentDidMount () {
    this.setState({ windowHeight: window.innerHeight })
  }

  render () {
    const { title = '', icons = [] } = this.props
    const { windowHeight, animated } = this.state

    return (
      <ScrollMonitoringWrapper offsets={{ top: (-windowHeight / 2) }} isInViewportEvent={() => this.setState({ animated: true })}>
        <div className={`${styles.iconBanner} ${animated && styles.animated}`}>
          <div className="container">
            <h2><span>{title}</span></h2>
            <div className={styles.icons}>
              {
                icons.map(({ icon = {}, title, link }) =>
                  <a href={link} target="_blank">
                    <div className={styles.imageWrapper}>
                      <Image {...icon} />
                    </div>
                    { title && <span>{title}</span> }
                  </a>
                )
              }
            </div>
          </div>
        </div>
      </ScrollMonitoringWrapper>
    )
  }
}

export default IconBanner
