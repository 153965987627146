import React from 'react'
import { Link } from 'gatsby'
import RoundArrowButton from '../../atoms/RoundArrowButton'
import DottedNav from '../../molecules/DottedNav'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './horizontalTitleAndContent.module.scss'


class HorizontalTitleAndContent extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      windowHeight : 750,
      isVisible: false,
    }
  }

  componentDidMount () {
    this.setState({ windowHeight: window.innerHeight })
  }

  animate = isVisible => {
    if (this.props.order === 0) {
      setTimeout(isVisible => this.setState({ animated: true, isVisible }), 1000)
    } else {
      this.setState({ animated: true, isVisible })
    }

    return isVisible
  }

  render () {
    const { title, content, cta_text, internal_link, nav = {}, unmount = false, invert, navigation_title } = this.props
    const { animated, windowHeight, isVisible } = this.state

    return (
      <ScrollMonitoringWrapper offsets={{ top: (-windowHeight / 3) }} isInViewportEvent={this.animate}>
      <div id={navigation_title && `${navigation_title}-id`} data-is-visible={isVisible} className={`${styles.horizontalTitleAndContent} ${animated && !unmount && styles.animated} ${invert && styles.invert}`}>
        <div>
          <div className={styles.titleWrapper}>
            <h2><span>{ title }</span></h2>
          </div>
          <div className={styles.content}>
            <div>
              <div className={styles.copy} dangerouslySetInnerHTML={{ __html: content }} />
              <div className={styles.links}>
                { cta_text && internal_link && <RoundArrowButton to={internal_link} text={cta_text}  /> }
              </div>
              { nav && nav.count > 0 && <DottedNav visible={animated} nav={nav} change={nav.change} /> }
            </div>
          </div>
        </div>
      </div>
      </ScrollMonitoringWrapper>
    )
  }
}

export default HorizontalTitleAndContent
