import React from 'react'
import Image from '../../atoms/Image'
import uuid from 'uuid'
import { Link } from 'gatsby'
import { getSlug } from '../../../lib/helpers'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './testimonialList.module.scss'

class TestimonialList extends React.Component {
  constructor () {
    super()

    this.state = {
      mounted: false,
      windowHeight: 750,
      keys: [],
      isVisible: false
    }
  }

  componentDidMount () {
    let keys = Array(this.props.testimonials.length).fill(uuid.v4())
    this.setState({ keys, windowHeight: window.innerHeight })
  }

  render () {
    const { mounted, windowHeight, isVisible } = this.state
    const { testimonials, title, copy, internal_link, cta_text, cta_text_two, internal_link_two, navigation_title } = this.props

    return (
      <ScrollMonitoringWrapper offsets={{ top: (-windowHeight / 2) }} isInViewportEvent={isVisible => this.setState({ mounted: true, isVisible })}>
        <section id={navigation_title && `${navigation_title}-id`} data-is-visible={isVisible} className={`${styles.testimonialList} ${mounted && `${styles.mounted}`}`}>
          <div className="container">
            <ul className={styles.testimonials}>
              {
                testimonials.map(({ name, caption, relation, image = {} }) => (
                  <li>
                    <div>
                      {
                        image.localFile &&
                        <div className={styles.imageWrapper}>
                          <Image {...image} />
                        </div>
                      }
                      <p>
                        <strong>{ name }</strong>
                        <span>{ relation }</span>
                      </p>
                    </div>
                    <p>{ caption }</p>
                  </li>
                ))
              }
            </ul>
            <div className={styles.content}>
              <div>
                <h2><span>{ title }</span></h2>
                <div className={styles.copy} dangerouslySetInnerHTML={{ __html: copy }} />
                <div className={styles.links}>
                  { cta_text && internal_link && <Link to={ getSlug(internal_link) } className="btn">{ cta_text }</Link> }
                  { cta_text_two && internal_link_two && false && <Link to={ getSlug(internal_link_two) } className="btn hollow">{ cta_text_two }</Link> }
                </div>
              </div>
            </div>
          </div>
        </section>
      </ScrollMonitoringWrapper>
    )
  }
}

export default TestimonialList
