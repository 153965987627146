import React from 'react'

import FeaturedShowcaseCarousel from '../components/organisms/FeaturedShowcaseCarousel'
import ContentAndImageSlideshow from '../components/organisms/ContentAndImageSlideshow'
import IphonesAndContent from '../components/molecules/IphonesAndContent'
import ImageAndContent from '../components/molecules/ImageAndContent'
import IconBanner from '../components/molecules/IconBanner'
import ContentAndIconWithSideBlock from '../components/molecules/ContentAndIconWithSideBlock'
import HorizontalTitleAndContent from '../components/molecules/HorizontalTitleAndContent'
import TestimonialList from '../components/organisms/TestimonialList'
import IphoneCarousel from '../components/organisms/IphoneCarousel'

export default function LoadBlock({ layout = [], containerProps = {}, fromREST = false }) {
  if (!layout) {
    return
  }

  let replacePrefix = str => str.replace('WordPressAcf_', '')
  let rendered = []

  layout.map((module, index) => {
    if (!fromREST && !module.id) {
      return
    }

    switch (fromREST ? module.acf_fc_layout : replacePrefix(module.__typename)) {
      case 'featured_showcase_carousel':
        rendered.push(
          <FeaturedShowcaseCarousel
            key={`FeaturedShowcaseCarousel-${module.index}`}
            slides={module.featured_showcase_carousel_slides}
            navigation_title={module.navigation_title}
          />
        )
        break
      case 'iphones_and_content':
        rendered.push(
          <IphonesAndContent
            key={`IphonesAndContent-${module.index}`}
            {...module}
          />
        )
        break
      case 'image_and_content':
        rendered.push(
          <ImageAndContent
            key={`ImageAndContent-${module.index}`}
            {...module}
          />
        )
        break
      case 'horizontal_title_and_content':
        rendered.push(
          <HorizontalTitleAndContent
            key={`HorizontalTitleAndContent-${module.index}`}
            order={rendered.length}
            {...module}
          />
        )
        break
      case 'content_and_image_slide_show':
        rendered.push(
          <ContentAndImageSlideshow
            key={`ContentAndImageSlideshow-${module.index}`}
            {...module}
          />
        )
        break
      case 'icon_banner':
        rendered.push(
          <IconBanner
            key={`IconBanner-${module.index}`}
            {...module}
          />
        )
        break
      case 'content_and_icons_with_side_block':
        rendered.push(
          <ContentAndIconWithSideBlock
            key={`ContentAndIconWithSideBlock-${module.index}`}
            {...module}
          />
        )
        break
      case 'testimonial_list':
        rendered.push(
          <TestimonialList
            key={`TestimonialList-${module.index}`}
            {...module}
          />
        )
        break
      case 'iphone_carousel':
        rendered.push(
          <IphoneCarousel
            key={`IphoneCarousel-${module.index}`}
            {...module}
          />
        )
        break
      default:
        console.warn(`Section mismatch encountered at index ${ index }`, module.index)
        return null
    }

    return rendered
  })

  return rendered
}
