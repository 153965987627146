import React from 'react'
import uuid from 'uuid'
import IphonesAndContent from '../../molecules/IphonesAndContent'
import ImageAndContent from '../../molecules/ImageAndContent'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './contentAndImageSlideShow.module.scss'

class ContentAndImageSlideShow extends React.Component {
  constructor () {
    super()

    this.state = {
      mounted: false,
      animated: false,
      index: 0,
      prepareAnimation: false,
      keys: [],
      isVisible: false,
      windowHeight: 750,
    }
  }

  animateSlide (i) {
    this.setState({ prepareAnimation: true })
    setTimeout(() => this.setState({ index: i ? i : (this.state.index + 1) === this.props.slides.length ? 0 : this.state.index + 1 }), 650)
    setTimeout(() => this.setState({ prepareAnimation: false }, () => setTimeout(() => this.setState({ animated: true }), 1000)), 1000)
  }

  goToSlide = i => {
    this.animateSlide(i)
    clearInterval(this.interval)
  }

  initiateSlideShow = isVisible => {
    this.setState({ animated: true })

    if (this.state.isVisible !== isVisible) {
      this.setState({ isVisible })
    }

    if (this.props.slides && this.props.slides.length > 1) {
      this.interval = setInterval(() => this.animateSlide(), 7500)
    }
  }

  componentDidMount () {
    this.setState({ windowHeight: window.innerHeight })
    let keys = Array(this.props.slides.length).fill(uuid.v4())
    this.setState({ keys })

    setTimeout(() => this.setState({ mounted: true }), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleSlide = slide => {
    switch (slide.type) {
      case 'iphone_slide':
        return <IphonesAndContent key={this.state.keys[this.state.index]} {...slide} unmount={this.state.prepareAnimation} nav={{ index: this.state.index, count: this.props.slides ? this.props.slides.length : 0, change: this.goToSlide }} />
      default:
        return <ImageAndContent key={this.state.keys[this.state.index]} {...slide} image={slide.images[0].image} unmount={this.state.prepareAnimation} nav={{ index: this.state.index, count: this.props.slides ? this.props.slides.length : 0, change: this.goToSlide }} />
    }
  }


  render () {
    const { index, mounted, animated, isVisible, windowHeight } = this.state
    const { slides, navigation_title } = this.props

    return (
      <ScrollMonitoringWrapper offsets={{ top: (-windowHeight / 1.25) }} isInViewportEvent={this.initiateSlideShow}>
        <section id={navigation_title && `${navigation_title}-id`} data-is-visible={isVisible} className={`${styles.contentAndImageSlideShow} ${mounted && `${styles.mounted}`}`}>
          { this.handleSlide(slides[index]) }
        </section>
      </ScrollMonitoringWrapper>
    )
  }
}

export default ContentAndImageSlideShow
