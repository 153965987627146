import React from 'react'
import { Link } from 'gatsby'
import { getSlug } from '../../../lib/helpers'
import Image from '../../atoms/Image'
import RoundArrowButton from '../../atoms/RoundArrowButton'
import DottedNav from '../../molecules/DottedNav'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './imageAndContent.module.scss'


class ImageAndContent extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      windowHeight : 750,
      isVisible: false,
    }
  }

  componentDidMount () {
    this.setState({ windowHeight: window.innerHeight })
  }

  render () {
    const { title, content, cta_text, internal_link, nav = {}, unmount = false, image = {}, invert, navigation_title } = this.props
    const { animated, windowHeight, isVisible } = this.state

    return (
      <ScrollMonitoringWrapper offsets={{ top: (-windowHeight / 1.25) }} isInViewportEvent={isVisible => this.setState({ animated: true, isVisible })}>
      <div id={navigation_title && `${navigation_title}-id`} data-is-visible={isVisible} className={`${styles.imageAndContent} ${animated && !unmount && styles.animated} ${invert && styles.invert}`}>
        <div>
          <div className={styles.imageWrapper}>
            {
              (image.localFile || image.url) &&
              <div className={styles.imageWrapperInner}>
                <Image critical={true} {...image} />
              </div>
            }
          </div>
          <div className={styles.content}>
            <div>
              <h2><span>{ title }</span></h2>
              <div className={styles.copy} dangerouslySetInnerHTML={{ __html: content }} />
              <div className={styles.links}>
                { cta_text && internal_link && <RoundArrowButton to={getSlug(internal_link)} text={cta_text}  /> }
              </div>
              { nav && nav.count > 0 && <DottedNav visible={animated} nav={nav} change={nav.change} /> }
            </div>
          </div>
        </div>
      </div>
      </ScrollMonitoringWrapper>
    )
  }
}

export default ImageAndContent
