import React, { Component } from 'react'
import Image from '../../atoms/Image'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import { getSlug } from '../../../lib/helpers'
import { withState } from 'recompose'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './iphoneCarousel.module.scss'

class IphoneCarousel extends Component {
  constructor () {
      super()

      this.state = {
        actived: false,
        showCarousel: false,
        showReturnButton: true,
        inScreen: false,
        windowHeight: 750,
        preview: false,
      }
  }

  componentDidMount () {
    this.setState({ windowHeight: window.innerHeight })
  }

  render () {

    const { windowHeight, preview } = this.state
    const { navigation_title, call_to_action_link } = this.props

    return (
      <ScrollMonitoringWrapper offsets={{ top: (-windowHeight / 2) }} isInViewportEvent={isVisible => this.setState({ inScreen: true })}>
        <section id={navigation_title && `${navigation_title}-id`} className={`${styles.iphoneCarousel} ${preview ? styles.preview : undefined} ${this.state.showCarousel ? styles.centered : undefined}`}>
          <div className={`${styles.copy} ${this.state.showCarousel ? styles.centered : undefined} ${this.state.inScreen ? styles.inScreen : undefined}`}>
            <h2><span>{this.props.title}</span></h2>
            <span className={styles.caption} dangerouslySetInnerHTML={{ __html: this.props.caption }} />
            <div className={styles.links}>
              {
                this.props.call_to_action_text
                  ? <Link className="btn" to={getSlug(call_to_action_link)}>{this.props.call_to_action_text}</Link>
                  : <div className={styles.appLinks}>
                      { this.props.icons.appStore.icon && <a rel="noopener noreferrer" to={call_to_action_link} target="_blank" rel="noopener noreferrer"><img src={this.props.icons.appStore.icon.url} alt={this.props.icons.appStore.icon.alt} /></a> }
                      { this.props.icons.googlePlay.icon && <a rel="noopener noreferrer" to={call_to_action_link} target="_blank" rel="noopener noreferrer"><img src={this.props.icons.googlePlay.icon.url} alt={this.props.icons.googlePlay.icon.alt} /></a> }
                    </div>
              }
            </div>
          </div>
          <button
            className={`${styles.arrow} ${styles.rightArrow} ${styles.activeToggler}`}
            onClick={() => this.setState({ showCarousel: true })}
            onMouseEnter={e => this.setState({ preview: true })}
            onMouseLeave={e => this.setState({ preview: false })}
          >
            <i className="fas fa-chevron-right"></i>
            <i className="fas fa-chevron-right"></i>
          </button>
          <button className={`${styles.arrow} ${styles.leftArrow} ${styles.activeToggler} ${this.state.showReturnButton ? styles.visible : undefined}`} onClick={() => this.setState({ showCarousel: false })}>
            <i className="fas fa-chevron-left"></i>
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className={`${styles.carouselWrapper} ${this.state.showCarousel ? styles.centered : undefined} ${this.state.inScreen ? styles.inScreen : undefined}`}>
            <Carousel slides={this.props.slides} toggleReturnButton={(val) => this.setState({ showReturnButton: val })} />
          </div>
        </section>
      </ScrollMonitoringWrapper>
    )
  }
}

const Arrow = ({ className, onClick, right = false }) => (
  <a
    className={`${styles.arrow} ${styles.slickArrow} ${styles.activeToggler} ${right && styles.right} ${className}`}
    onClick={onClick}
  >
    <i className={`fas ${right ? 'fa-chevron-right' : 'fa-chevron-left'}`}></i>
    <i className={`fas ${right ? 'fa-chevron-right' : 'fa-chevron-left'}`}></i>
  </a>
)

const enhance = withState('settings', 'updateSettings', (props) => ({
  dots: false,
  infinite: false,
  speed: 750,
  slidesToShow: props.slidesToShow || 4,
  slidesToScroll: 1,
  swipe: false,
  waitForAnimate: true,
  cssEase: 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
  useCSS: true,
  draggable: false,
  useTransform: true,
  nextArrow: <Arrow right={true} />,
  prevArrow: <Arrow />,
  responsive: [
    { breakpoint: 768, settings: { slidesToShow: 1 } },
  ],
  afterChange: (currentSlide) => currentSlide === 0 ? props.toggleReturnButton(true) : props.toggleReturnButton(false)
}))

const Carousel = enhance(({
  slides = [],
  showAll,
  settings,
  noResultsMessage = "Sorry we couldn't find any results relating to your query."
}) => (
  <div className={styles.carousel}>
  {
    !slides.length
      ? <p className={styles.errorMessage} dangerouslySetInnerHTML={{ __html: noResultsMessage }} />
      : <Slider {...settings}>
          {
            slides.map(({ image, title }, index) => (
              <div className={`${styles.slide} ${styles.iphoneSlide}`} key={index}>
                <div className={styles.imageWrapper}>
                  <Image {...image} contain={true} />
                </div>
                <p>{title}</p>
              </div>
            ))
          }
        </Slider>
  }
  </div>
))

export default IphoneCarousel
