import React from 'react'
import styles from './dottedNav.module.scss'

export default ({ nav, unmount }) =>
  <ul className={styles.dottedNav}>
    {
      Array(nav.count)
      .fill(0)
      .map((el, i) =>
        <li className={nav.index === i && styles.active}>
          <button onClick={() => nav.change(i)}></button>
        </li>
      )
    }
  </ul>
