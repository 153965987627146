import React from 'react'
import { Link } from 'gatsby'
import Image from '../../atoms/Image'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './content-and-icon-with-side-block.module.scss'

class ContentAndIconWithSideBlock extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      windowHeight : 750
    }
  }

  componentDidMount () {
    this.setState({ windowHeight: window.innerHeight })
  }

  render () {
    const { title = '', caption = '', icons = [] } = this.props
    const { windowHeight, animated } = this.state

    return (
      <ScrollMonitoringWrapper offsets={{ top: (-windowHeight / 2) }} isInViewportEvent={() => this.setState({ animated: true })}>
        <div className={`${styles.contentAndIconWithSideBlock} ${animated && styles.animated}`}>
          <div className="container">
            <div className={styles.content}>
              <h2><span>{title}</span></h2>
              <div className={styles.caption}>
                <div dangerouslySetInnerHTML={{ __html: caption }} />
              </div>
            </div>
            <div className={styles.icons}>
              { icons.map(({ image = {}, link }) => <Link to={link}><div className={styles.imageWrapper}><Image {...image} /></div></Link>) }
            </div>
          </div>
        </div>
      </ScrollMonitoringWrapper>
    )
  }
}

export default ContentAndIconWithSideBlock
