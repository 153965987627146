import React from 'react'
import { Link } from 'gatsby'
import styles from './roundArrowButton.module.scss'

const RoundArrowButton = ({ text = '', to = '', submit = false }) =>
  <>
    {
      !submit
      ? <Link to={ to } className={styles.arrow}>
          <span>{ text }</span>
          <button>
            <i className="fas fa-chevron-right"></i>
            <i className="fas fa-chevron-right"></i>
          </button>
        </Link>
      : <button type="submit" className={styles.arrow}>
          <span>{ text }</span>
          <div>
            <i className="fas fa-chevron-right"></i>
            <i className="fas fa-chevron-right"></i>
          </div>
        </button>
    }
  </>

export default RoundArrowButton
